@import url(https://fonts.googleapis.com/css?family=Raleway&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Comfortaa&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Archivo&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Oswald&display=swap);
@import url(https://fonts.googleapis.com/css?family=Oswald&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Archivo+Narrow&display=swap);
/* html {
  height: 100%;
} */

body {
  margin: 0;
  font-family: 'Raleway', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* height: 100%; */
}

/* #root {
  height: 100%;
} */

code {
  font-family: 'Raleway', sans-serif;
}

* {
  box-sizing: border-box;
}

.css-1vr111p-option {
  color: black !important;
}

.MuiInputBase-root,
.MuiButtonBase-root,
.basic-multi-select {
  border-radius: 0 !important;
}

.MuiTypography-body1 {
  font-family: 'Archivo Narrow' !important;
}

@font-face {
  font-family: 'DIN';
  src: local('DIN'), url(/static/media/DIN.0a82b71f.ttf) format('truetype');
}

@font-face {
  font-family: 'DIN Bold';
  src: local('DIN Bold'), url(/static/media/DIN-Bold.75c6da4d.ttf) format('truetype');
}

/* @font-face {
  font-family: 'Archivo Narrow';
  src: url('./assets/fonts/archivonarrow-regular-webfont.woff2') format('woff2'),
       url('./assets/fonts/archivonarrow-regular-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
} */

@font-face {
  font-family: 'Archivo Narrow';
  src: url(/static/media/ArchivoNarrow-Regular.dbcd1ecf.ttf) format('truetype');
}

@font-face {
  font-family: 'Archivo Narrow Bold';
  src: url(/static/media/ArchivoNarrow-Bold.0d868e97.ttf) format('truetype');
}

@font-face {
  font-family: 'Archivo Narrow Medium';
  src: url(/static/media/ArchivoNarrow-Medium.27c63ed5.ttf) format('truetype');
}

.community_container__1KZFa{display:flex;flex-direction:column;width:100%;flex-shrink:0}.community_container__1KZFa .community_splashSection__emtpI{min-height:600px;background-size:cover;background-repeat:no-repeat;background-position:center;display:flex;align-items:center;justify-content:center}.community_container__1KZFa .community_splashSection__emtpI .community_title__21lqX{color:#fff;text-transform:uppercase;font-size:68px;letter-spacing:2.1px;text-align:center}.community_container__1KZFa .community_languageSelect__3khwa{color:#2c0040;background-color:#fff;border:none;outline:none;font-family:'Archivo Narrow';font-size:16px;letter-spacing:0;margin:15px 15px 15px auto}.community_container__1KZFa .community_appsSection__3FNci{text-align:center;padding-top:50px}.community_container__1KZFa .community_appsSection__3FNci .community_subtitle__24PfM{color:#6e3694;text-transform:uppercase;font-size:22px;font-weight:500;letter-spacing:0.68px;padding-bottom:50px}@media only screen and (max-width: 680px){.community_container__1KZFa .community_splashSection__emtpI{min-width:330px}}

.AppCard_container__2bOCV{display:flex;flex-direction:row;max-width:1000px;margin:auto;padding-bottom:50px}.AppCard_container__2bOCV .AppCard_appImage__3nxiJ{width:386px;padding-left:50px;padding-right:50px}.AppCard_container__2bOCV .AppCard_appCardContent__3QtFm{text-align:left;padding-right:50px}.AppCard_container__2bOCV .AppCard_appCardContent__3QtFm .AppCard_appCardTitle__GQWZm{color:#1a1a1a;font-size:22px;text-transform:uppercase;font-weight:500;letter-spacing:0.68px;padding-bottom:10px}.AppCard_container__2bOCV .AppCard_appCardContent__3QtFm .AppCard_appCardBody__21p5K{height:90px;overflow:auto;color:rgba(26,26,26,0.7);font-family:'Archivo Narrow';font-size:18px;font-weight:500;letter-spacing:0.56px;line-height:25px;margin-bottom:10px}.AppCard_container__2bOCV .AppCard_appCardContent__3QtFm .AppCard_appCardSignIn__2diOC{height:51px;width:140px;background-color:#efac42;color:#fff;font-family:'Archivo Narrow';font-size:16px;font-weight:bold;letter-spacing:0;line-height:22px;text-align:center;border:none;cursor:pointer;margin:5px 10px 5px 10px}@media only screen and (max-width: 680px){.AppCard_container__2bOCV{max-width:100%;flex-direction:column}.AppCard_container__2bOCV .AppCard_appCardContent__3QtFm{text-align:center;padding-left:20px;padding-right:20px}.AppCard_container__2bOCV .AppCard_appCardContent__3QtFm .AppCard_appCardBody__21p5K{height:auto}}

.AddCircle_add__35wau{display:flex;flex-direction:row;align-items:center;padding-bottom:0.5em}.AddCircle_add__35wau .AddCircle_circle__d7_V7{border-radius:50%;width:27.4px;height:27.4px;background-color:#6e3694;margin-right:0.5em}.AddCircle_add__35wau .AddCircle_circle__d7_V7::before{content:'+';height:22.4px;width:27.4px;font-size:27.4px;display:flex;align-items:center;justify-content:center;font-weight:bold;color:#fff;cursor:pointer}.AddCircle_add__35wau .AddCircle_label__2vNB1{margin-left:0.5rem}

.Portal{font-family:Oswald;display:flex;flex-direction:column;min-height:100%}

