
.add {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-bottom: 0.5em;

  .circle {
    border-radius: 50%;
    width: 27.4px;
    height: 27.4px;
    background-color: #6e3694;
    margin-right: 0.5em;
  }

  .circle::before {
    content: '+';
    height: 22.4px;
    width: 27.4px;
    font-size: 27.4px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: bold;
    color: #fff;
    cursor: pointer;
  }

  .label {
    margin-left: 0.5rem;
  }
}
