@import url('https://fonts.googleapis.com/css?family=Raleway&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Comfortaa&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Archivo&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Oswald&display=swap');

/* html {
  height: 100%;
} */

body {
  margin: 0;
  font-family: 'Raleway', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* height: 100%; */
}

/* #root {
  height: 100%;
} */

code {
  font-family: 'Raleway', sans-serif;
}

* {
  box-sizing: border-box;
}

.css-1vr111p-option {
  color: black !important;
}

.MuiInputBase-root,
.MuiButtonBase-root,
.basic-multi-select {
  border-radius: 0 !important;
}

.MuiTypography-body1 {
  font-family: 'Archivo Narrow' !important;
}

@font-face {
  font-family: 'DIN';
  src: local('DIN'), url(./assets/fonts/DIN.ttf) format('truetype');
}

@font-face {
  font-family: 'DIN Bold';
  src: local('DIN Bold'), url(./assets/fonts/DIN-Bold.ttf) format('truetype');
}

/* @font-face {
  font-family: 'Archivo Narrow';
  src: url('./assets/fonts/archivonarrow-regular-webfont.woff2') format('woff2'),
       url('./assets/fonts/archivonarrow-regular-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
} */

@font-face {
  font-family: 'Archivo Narrow';
  src: url(./assets/fonts/ArchivoNarrow-Regular.ttf) format('truetype');
}

@font-face {
  font-family: 'Archivo Narrow Bold';
  src: url(./assets/fonts/ArchivoNarrow-Bold.ttf) format('truetype');
}

@font-face {
  font-family: 'Archivo Narrow Medium';
  src: url(./assets/fonts/ArchivoNarrow-Medium.ttf) format('truetype');
}
